import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The new airborne geophysical coverage of Nigeria was completed in July 2010 with
the addition of  airborne gravity and magnetic survey over the region of the
Niger Delta. The aeromagnetic and  gamma-ray spectrometry data acquired up to
July 2009 were released in January this year. The  new survey now covers the
whole land area of the country - over 2 million km of flying. `}</p>
    <p>{`The release of the Phase Two data took place with due ceremony in Abuja, Nigeria
on January 26.  Representatives of the Ministry of Mines and Steel Development
also gave promotional  presentations at the 2010 Mining Indaba in Cape Town,
South Africa in February and at the 2010  PDAC in Toronto, Canada in March. More
information on the World Bank sponsored Sustainable  Management of Mineral
Resources Project (SMMRP) in Nigeria may be found on the website of  the
`}<a parentName="p" {...{
        "href": "/"
      }}>{`Ministry of Mines Steel Development`}</a>{`. The Powerpoint presentation made by Colin
Reeves in  Abuja in January may be downloaded `}<a parentName="p" {...{
        "href": "/"
      }}>{`here`}</a>{`. The poster presented at the
SEG Annual Meeting in  October 2010 by the interpretation team may be seen
below. `}</p>
    <PublishDate mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      